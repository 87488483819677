// colors

$primary-color: #0B3C5D;
$primary-color-focus: #082E48;
$secondary-color: #61B4E5;
$error-color: #D8544C;
$grid-stripe-color: #F6F6F6;
$gray-color: #696969;
$dark-gray-color: #747474;
$gray-brackground: #EDEDED;
$gray-secondary: #828282;
$gray-third: #6F6F6F;
$gray-fourth: #808080;
$title-color: #2A589E;
$border-color: #DFDFDF;
$white: #FFFFFF;
$black: #000000;
$green: #008000;
$yellow: #D19E00;
$dark-yellow: #F7C450;
$border-gray: #E0E0E0;
$submit-color: #1ABC9C;
$dark-blue: #102039;
$light-yellow: #FFC102;
$active-green: #28A745FF;
$card-bg-color: #F5F5F5;
$download-link-color: #61B4E5;
$green-background: #1ABC9C3F;

// font-family

$monserrat: Montserrat, sans-serif;
$roboto: Roboto, san-serif;
